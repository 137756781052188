<template>
  <div id="app">
    <div class="menu-box">
      <div class="menu-box-logo">
        <img src="./assets/img/logo.png" />
        <img src="./assets/img/logo_m.png" />
      </div>
      <dl class="menu-box-link">
        <dd
          :class="index == pageIndex ? menuHover : ''"
          v-for="(menuItem, index) in pageData"
          :key="index"
          @click="getPage(menuItem.id, menuItem.link)"
        >
          {{ menuItem.text
          }}<i v-if="menuItem.menu" class="iconfont icon-xiala-copy"></i>

          <div class="xiala-box" v-if="menuItem.menu">
            <div class="before-line"></div>
            <label
              :class="kIndex == menuIndex ? '_hover' : ''"
              v-for="(mitem, kIndex) in menuItem.menu"
              :key="kIndex"
              @click="getProPage(mitem.mid, mitem.link)"
              >{{ mitem.text }}</label
            >
          </div>
        </dd>
      </dl>
      <div class="menu-box-right" @click="mobileMenu = !mobileMenu">
        <i class="iconfont icon-guanbi" v-if="mobileMenu"></i>
        <i class="iconfont icon-caidan" v-else></i>
      </div>
    </div>
    <div class="mobile-menu-box" v-if="mobileMenu">
      <dl v-for="(menuItem, index) in pageData" :key="index">
        <dd
          :class="index == pageIndex ? menuHover : ''"
          @click="getPage(menuItem.id, menuItem.link)"
        >
          {{ menuItem.text }}
        </dd>
        <dt v-if="menuItem.menu && pageIndex == 3">
          <label
            :class="kIndex == menuIndex ? '_hover' : ''"
            v-for="(mitem, kIndex) in menuItem.menu"
            :key="kIndex"
            @click="getProPage(mitem.mid, mitem.link)"
            >{{ mitem.text }}</label
          >
        </dt>
      </dl>
    </div>
    <router-view
      :key="$route.fullPath"
      @viewMenuIndex="getViewMenuIndex"
      @menuProIndex="menuProIndex"
      @menuHover="getMenuHover"
    />
    <footer class="footer-box">
      <div class="footer-box-con">
        <div class="box-con-logo">
          <img src="./assets/img/b_logo.png" />
        </div>
        <dl class="box-con-right">
          <dd>
            <img src="./assets/img/code.jpg" /><span>晨兴瑞华官方公众号</span>
          </dd>
          <dt>
            <strong>办公地址</strong>
            <span>地址：山西省太原市迎泽区南内环并州路交叉口西北角昆仑御写字楼3113室</span>
            <span>邮编：030000</span>
            <span>电话：13311159232</span>
            <span>邮件：risehua@126.com</span>
          </dt>
        </dl>
      </div>
      <span class="copy-text"
        >&copy; 2023 山西晨兴瑞华科技有限公司 版权所有 晋ICP备
        2023017357号</span
      >
    </footer>
  </div>
</template>
<script>
import "./assets/font/iconfont.css";
import "animate.css";
export default {
  name: "AboutView",
  data() {
    return {
      pageIndex: 0,
      menuIndex: 0,
      menuHover: "_hover",
      mobileMenu: false,
      flag: null,
      pageData: [
        {
          id: 0,
          text: "首页",
          link: "/",
        },
        {
          id: 1,
          text: "公司简介",
          link: "/profile",
        },
		{
		  id: 2,
		  text: "解决方案",
		  link: "/programme"
		},
        {
          id: 3,
          text: "精品案例",
          link: "/product",
          menu: [
            // {
            //   mid: 0,
            //   text: "电商erp",
            //   link: "erp",
            // },
            {
              mid: 0,
              text: "校园支付",
              link: "payment",
            },
            {
              mid: 1,
              text: "电商小程序",
              link: "applet",
            },
            {
              mid: 2,
              text: "在线学习平台",
              link: "platform",
            },
            {
              mid: 3,
              text: "云存储",
              link: "storage",
            },
            {
              mid: 4,
              text: "晨兴智慧",
              link: "gpt",
            },
            {
              mid: 5,
              text: "赛事小程序",
              link: "saishi",
            },
            {
              mid: 6,
              text: "720云项目",
              link: "yun",
            },
            {
              mid: 7,
              text: "追溯管理",
              link: "zhuisu",
            },
            {
              mid: 8,
              text: "铁路装备网",
              link: "tielu",
            },
            {
              mid: 9,
              text: "智慧大屏",
              link: "daping",
            },
            {
              mid: 10,
              text: "电子海报",
              link: "dianzi",
            },
			{
			  mid: 11,
			  text: "宣传页设计",
			  link: "xuanchuan",
			},
			{
			  mid: 12,
			  text: "线上法律咨询",
			  link: "falv",
			},
			{
			  mid: 13,
			  text: "web3.0代币",
			  link: "daibi",
			
			},
			{
			  mid: 14,
			  text: "中考成绩管理",
			  link: "zhongkao",
			}
          ],
        },
        {
          id: 4,
          text: "关于我们",
          link: "/about",
        },
        {
          id: 5,
          text: "加入我们",
          link: "/join",
        },
      ],
    };
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.$store.commit("getFlag", this.flag);
  },
  methods: {
    getMenuHover(val) {
      this.menuHover = val;
    },
    menuProIndex(id) {
      this.menuIndex = id;
    },
    getViewMenuIndex(val) {
      this.pageIndex = val;
    },
    getProPage(mid, val) {
      this.menuIndex = mid;
      this.$store.commit("getProLink", val);
      this.mobileMenu=false
      this.$router
        .push({
          path: "/product",
        })
        .catch((err) => err);
    },
    getPage(id, val) {
      this.pageIndex = id;
      
      if (id != 3) {
        this.mobileMenu=false
        this.menuHover = "_hover";
        this.$store.commit("getPage", val);
        this.$router
          .push({
            path: val,
          })
          .catch((err) => err);
      }
    },
  },
};
window.onload = function () {
  // 禁用双指放大
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  // 禁用双击放大
  let lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    function (event) {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mobile-menu-box {
  position: fixed;
  z-index: 98;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  padding: 8rem 0rem 1rem 0rem;
  align-items: center;
  box-shadow: 1rem 1rem 1rem 1rem rgba(0, 0, 0, 0.103);
  overflow-x: hidden;
  overflow-y: auto;

  & dl {
    position: relative;
    display: block;
  }

  & dl dd,
  dl dt label {
    width: 15rem;
    min-height: 3.6rem;
    padding: 1rem 0;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  & dl dt {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  & dl dd,
  dl dt label {
    position: relative;
    overflow: hidden;
  }
  & dl dd:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.06rem;
    background: #eeebf4;
  }
  & dl:last-child dd:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.06rem;
    background: #eeebf4;
  }

  & dl dd:hover,
  dl dd._hover {
    background: #eeebf4;
  }

  & dl dt label:hover,
  dl dt label._hover {
    background: #fafaff;
    color: #a073d4;
  }
  & dl dt label:hover:before,
  dl dt label._hover:before {
    content: "";
    width: 0.5rem;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #a073d4;
  }
}
.mt-3rem {
  margin-top: -3rem;
  & .mt-line-box {
    height: 3rem;
    display: block;
    clear: both;
    overflow: hidden;
  }
}
.pb-5rem {
  padding-bottom: 5rem;
}
.page-title-box {
  position: relative;
  overflow: hidden;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 4.6rem;

  & strong {
    font-size: 2.82rem;
    color: #999899;
    position: relative;
    z-index: 5;
    font-weight: normal;
  }
  & span {
    font-size: 2.5rem;
    color: rgba($color: #999899, $alpha: 0.2);
    position: relative;
    z-index: 4;
    margin-top: -1.8rem;

    &:after {
      content: "";
      width: 5.06rem;
      height: 0.25rem;
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      margin-left: -2.53rem;
      background: linear-gradient(90deg, #5f3590 0%, #a073d4 100%);
      border-radius: 0.25rem;
      z-index: 9;
    }
  }
  & label {
    padding: 2rem 0 2.5rem 0;
    font-size: 1.15rem;
    color: #6b6b6b;
  }
}
.plr {
  padding: 0 2.6rem;
}
.page-link-box {
  overflow: hidden;
  position: relative;
  margin-top: -3rem;
  z-index: 11;
  font-size: 1.46rem;
  color: #ffffff;
  padding: 2rem 0;

  .link-icon-text {
    display: -webkit-flex;
    display: flex;
    align-items: center;

    .router-link-active {
      color: #ffffff;
      text-decoration: none;
    }
  }

  & .link-icon {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    .iconfont {
      transform: rotate(-90deg);
    }
  }

  .link-box-text {
    width: 50%;
    background: linear-gradient(90deg, #5f3590 0%, #18469c 100%);
    transform: skew(10deg, 0deg);
    transform-origin: top left;
    left: -1rem;
    top: 0;
    bottom: 0;
    z-index: 1;
    position: absolute;
  }
}

.page-main-box {
  max-width: 88.75rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin: 0 auto;
}

.page-white-bg {
  background: #ffffff;
  box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.05);
  padding-bottom: 5.4rem;
}

.bananer-box {
  overflow: hidden;
  position: relative;
  z-index: 9;
  .bananer-box-img {
    overflow: hidden;
    & dd img,
    dt img {
      width: 100%;
    }
    & dt {
      display: none;
      opacity: 0;
    }
  }
}

.footer-box {
  padding-top: 4.5rem;
  background: #283040;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .footer-box-con {
    min-width: 57.44rem;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #3e4553 solid 0.06rem;
    padding-bottom: 3.4rem;

    .box-con-logo {
      width: 8.13rem;

      & img {
        width: 100%;
        display: block;
      }
    }

    .box-con-right {
      display: -webkit-flex;
      display: flex;
      align-items: center;
      position: relative;

      & dt {
        padding-left: 2.44rem;
        position: relative;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;


        strong {
          font-size: 1.04rem;
          color: #ffffff;
        }

        span {
          font-size: 0.89rem;
          color: #697486;
          padding-top: 0.7rem;
          display: block;
          clear: both;
        }
      }

      & dd {
        padding-right: 2.44rem;
        position: relative;
      }

      & dd:before {
        content: "";
        width: 0.06rem;
        position: absolute;
        right: 0.06rem;
        top: 0;
        bottom: 0;
        background: #3e4553;
      }

      & dd:after {
        content: "";
        width: 0.18rem;
        height: 1.38rem;
        position: absolute;
        right: 0;
        top: 0;
        background: #5f3590;
      }

      & dd img {
        width: 7.06rem;
        height: 7.06rem;
        background-color: #ffffff;
        display: block;
        margin: 0 auto;
      }

      & dd span {
        padding-top: 0.88rem;
        font-size: 0.78rem;
        color: #697486;
        display: block;
        clear: both;
        text-align: center;
      }
    }
  }

  .copy-text {
    display: block;
    font-size: 0.89rem;
    color: #697486;
    text-align: center;
    padding: 2rem;
  }
}

.menu-box {
  min-height: 7.5rem;
  // min-width: 60rem;
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(19, 57, 127, 1.0) 0%,
	rgba(59, 6, 102, 0.5) 50%,
    rgba(59, 6, 102, 0) 100%
  );
  padding: 1.565rem 3.13rem;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: "";
    height: 0.31rem;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #5f3590 0%, #18469c 100%);
  }

  .menu-box-logo {
    height: 4.38rem;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    & img:first-child {
      height: 100%;
      display: block;
    }

    & img:last-child {
      height: 100%;
      display: none;
    }
  }

  .menu-box-link {
    font-size: 1.46rem;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-right: 0rem;
    position: relative;
    height: 4.38rem;
    line-height: 4.38rem;

    & dd {
      padding: 0 2rem;
      cursor: pointer;
      color: #ffffff;
      position: relative;
      transition: 0.5s;
      font-weight: bold;

      i {
        position: absolute;
        right: 0;
        font-weight: normal;
      }

      .xiala-box {
        display: none;
      }
    }

    & dd:hover,
    dd._hover {
      color: #ffffff;

      &:after {
        content: "";
        width: 1.06rem;
        height: 0.25rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -0.58rem;
        background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
        border-radius: 0.25rem;
        z-index: 9;
      }

      i {
        position: absolute;
        right: 0;
        transform: rotate(180deg);
      }

      .xiala-box {
        display: block;
        width: 12rem;
        left: 0;
        min-height: 3rem;
        background: #ffffff;
        border-radius: 0.5rem;
        position: absolute;
        top: 4.1rem;
        overflow: hidden;
        display: -webkit-flex;
        display: flex;
        flex-flow: column;
        z-index: 10;

        .before-line {
          height: 0.25rem;
          width: 100%;
          background: linear-gradient(0deg, #5f3590 0%, #a073d4 100%);
          border-radius: 0.25rem;
          position: relative;
          z-index: 10;
        }

        & label {
          display: block;
          clear: both;
          text-align: center;
          font-size: 1rem;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          line-height: 3rem;
          border-top: #eeebf4 solid 0.06rem;
          color: #697486;
          font-weight: normal;
        }
        & label:hover,
        label._hover {
          color: #5f3590;
          background: #eeebf4;
        }
      }
    }
  }

  .menu-box-link:hover dd {
    /* 不透明度 */
    opacity: 0.7;
    /* 模糊度 */
    filter: blur(1px);
  }
  .menu-box-link dd:hover {
    /* 不透明度 */
    opacity: 1;
    /* 模糊度 */
    filter: blur(0px);
  }

  .menu-box-right {
    display: none;
    opacity: 0;
  }
}

.slide-left-enter-from {
  opacity: 0.2;
}

.slide-left-enter-to {
  opacity: 1;
}

.slide-left-leave-from {
  opacity: 1;
}

.slide-left-leave-to {
  opacity: 0.8;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.2s;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #d6d6d640;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(161, 161, 161, 0.8);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width: 1400px) {
  .menu-box {
    .menu-box-logo {
      & img:first-child {
        height: 100%;
        display: none;
      }

      & img:last-child {
        height: 100%;
        display: block;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .menu-box {
    .menu-box-link {
      & dd {
        padding: 0 1.2rem;
      }
      & dd:nth-child(3) {
        padding: 0 2rem 0 1.2rem;
      }

      & dd:hover,
      dd._hover {
        .xiala-box {
          width: 10.6rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .menu-box-right {
    opacity: 1 !important;
    position: absolute;
    z-index: 99;
    width: 6.69rem;
    height: 5rem;
    right: 0;
    top: 0;
    background: linear-gradient(30deg, #5f3590 0%, #18469c 100%);
    transform: skew(10deg, 0deg);
    transform-origin: top left;
    display: -webkit-flex !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & i {
      color: #ffffff;
      font-size: 2.5rem;
      transform: skew(-10deg, 0deg);
      opacity: 0.7;
    }
  }
  .menu-box-link {
    display: none !important;
  }
  .bananer-box {
    background: #eeebf4;
    height: 75vw;

    & .bananer-box-img {
      & dd {
        display: none;
        opacity: 0;
      }
      & dt {
        display: block;
        opacity: 1;
        img {
          height: 75vw;
        }
      }
    }
  }

  .page-link-box {
    margin-top: -2rem;
    padding: 1rem 0;
  }
}
@media screen and (max-width: 970px) {
  .footer-box .footer-box-con {
    min-width: auto;
    width: 100%;
    padding: 0 2rem 3.4rem 2rem;
  }
  .box-con-right dt{
    max-width: 23rem;
  }
}
@media screen and (max-width: 750px) {
  html,
  body,
  div,
  dl,
  dd,
  dt,
  p,
  ul,
  li,
  img {
    font-size: 13px;
  }
}
@media screen and (max-width: 660px) {
  .menu-box {
    min-height: 6.5rem;
    padding: 1rem 2.5rem;
  }
  .menu-box .menu-box-logo {
    height: 3.5rem;
  }
  .menu-box-right {
    width: 5.69rem;
    height: 4rem;
  }
  .menu-box-right i {
    font-size: 2rem;
  }
  .mobile-menu-box {
    padding: 6rem 0rem 1rem 0rem;
  }
  .mobile-menu-box dl dd,
  .mobile-menu-box dl dt label {
    font-size: 1.15rem;
  }
  .page-title-box strong {
    font-size: 2.5rem;
  }

  .page-title-box label {
    padding: 2rem 1rem 2.5rem 1rem;
    line-height: 1.8rem;
  }
  .page-white-bg {
    padding-bottom: 4rem;
  }
  .pb-5rem {
    padding-bottom: 4rem;
  }
  .footer-box .footer-box-con {
    width: auto;
  }
  .footer-box .footer-box-con .box-con-logo {
    display: none;
    opacity: 0;
  }
}
@media screen and (max-width: 530px) {
  .page-title-box strong {
    font-size: 2rem;
  }
  .page-title-box span {
    font-size: 2rem;
    margin-top: -1.2rem;
  }

  .footer-box .footer-box-con .box-con-right {
    flex-flow: column;
  }
  .footer-box .footer-box-con .box-con-right dd {
    padding-right: 0px;
    padding-bottom: 2rem;
  }
  .footer-box .footer-box-con .box-con-right dt {
    padding-left: 0px;
  }
  .footer-box .footer-box-con .box-con-right dd:before,
  .footer-box .footer-box-con .box-con-right dd:after {
    display: 0;
    opacity: 0;
  }
}
@media screen and (max-width: 530px) {
  .footer-box .copy-text {
    padding: 1.5rem;
  }
}
</style>