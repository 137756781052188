import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{title:"晨兴瑞华科技 - 首页"}
  },
  {
    path: '/profile',
    name: 'profile',
    component: function () {
      return import('../views/ProfileView.vue')
    },
    meta:{title:"晨兴瑞华科技 - 公司简介"}
  },
  {
    path: '/programme',
    name: 'programme',
    component: function () {
      return import('../views/ProgrammeView.vue')
    },
    meta:{title:"晨兴瑞华科技 - 解决方案"}
  },
  {
    path: '/product',
    name: 'product',
    component: function () {
      return import('../views/ProductView.vue')
    },
    meta:{title:"晨兴瑞华科技 - 精品案例"}
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    },
    meta:{title:"晨兴瑞华科技 - 关于我们"}
  },
  {
    path: '/join',
    name: 'join',
    component: function () {
      return import('../views/JoinView.vue')
    },
    meta:{title:"晨兴瑞华科技 - 加入我们"}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
      // @ts-ignore
      document.title = to.meta.title
  }
  next()//执行进入路由，如果不写就不会进入目标页
})
export default router
