<template>
  <div class="home">
    <div class="bananer-box">
      <span class="bananer-box-btn box-btn-left" @click="clickIcon('up')"
        ><i class="iconfont icon-xiala"></i
      ></span>
      <dl class="bananer-box-img">
        <dd>
          <img
            @mouseover="changeInterval(true)"
            @mouseleave="changeInterval(false)"
            v-for="item in imgArr"
            :key="item.id"
            :src="item.url"
            v-show="item.id === currentIndex"
          />
        </dd>
        <dt>
          <img
            @mouseover="changeInterval(true)"
            @mouseleave="changeInterval(false)"
            v-for="item in imgArr"
            :key="item.id"
            :src="item.url_m"
            v-show="item.id === currentIndex"
          />
        </dt>
      </dl>
      <span class="bananer-box-btn box-btn-right" @click="clickIcon('down')"
        ><i class="iconfont icon-xiala"></i
      ></span>
      <div class="banner-circle">
        <ul>
          <li
            @click="changeImg(item.id)"
            v-for="item in imgArr"
            :key="item.id"
            :class="item.id === currentIndex ? 'active' : ''"
          ></li>
        </ul>
      </div>
    </div>
    <div class="page-white-bg">
      <div class="page-main-box">
        <div class="page-title-box">
          <strong>我们能做什么？</strong>
          <span>What can we do?</span>
          <label
            >专注技术研发十余年，业务涉及医疗、健康、广电、政府机关、金融、教育等领域</label
          >
        </div>
        <dl class="home-tab-box">
          <dt>
            <div class="tab-box-top">
              <strong>APP定制开发</strong><i class="iconfont icon-APP"></i>
            </div>
            <div class="tab-box-bottom">
              提供基于IOS、Android和鸿蒙OS系统 的移动APP应用设计与开发。
            </div>
          </dt>
          <dt>
            <div class="tab-box-top">
              <strong>微信应用开发</strong
              ><i class="iconfont icon-xiaochengxu"></i>
            </div>
            <div class="tab-box-bottom">
              覆盖各商业领域的微信小程序开发、
              轻量化微信服务应用等服务，满足用户多样化需求。
            </div>
          </dt>
          <dt>
            <div class="tab-box-top">
              <strong>智能软硬件开发</strong
              ><i class="iconfont icon-tuiguang"></i>
            </div>
            <div class="tab-box-bottom">
              提供企业级智能软硬件开发解决方案，
              通过软件开发实现硬件产品的网络化、智能化。
            </div>
          </dt>
          <dt>
            <div class="tab-box-top">
              <strong>集成系统</strong><i class="iconfont icon-fuwu"></i>
            </div>
            <div class="tab-box-bottom">
              为企业提供营销系统、管理系统、服务系统等平台系统的开发搭建及网络定制等系统配套服务。
            </div>
          </dt>
          <dt>
            <div class="tab-box-top">
              <strong>AI人工智能</strong
              ><i class="iconfont icon-chuanzhongjianzhan"></i>
            </div>
            <div class="tab-box-bottom">
              通过AI人工智能技术为用户提供指纹识别，人脸识别，自动规划，智能搜索、自动程序设计，智能控制等方面的服务。
            </div>
          </dt>
          <dt>
            <div class="tab-box-top">
              <strong>其他开发</strong><i class="iconfont icon-ziyuan"></i>
            </div>
            <div class="tab-box-bottom">
              我们为品牌策划感染力强、形式多样的短视频内容，抓住受众碎片化时间通过互联网等媒体进行传播。
            </div>
          </dt>
        </dl>
      </div>
    </div>
    <div class="page-title-box">
      <strong>我们做过什么？</strong>
      <span>What have we done?</span>
      <label>十余年的互联网开发设计和技术沉淀，事实和作品最有话语权...</label>
    </div>
    <dl class="image-tab-box">
      <dd>
        <p>
          <strong>星辰智维</strong>
          <span>系统里融入了很多GPT或者AI元素</span>
          <span>多个纬度和领域实现了智能化请求与应答机制</span>
          <span @click="getProPage(4, 'gpt')">进一步了解 →</span>
        </p>
      </dd>
      <dd>
        <p>
          <strong>电商小程序</strong>
          <span
            >一个集商品展示、交易、物流、营销推广等功能于一体的线上批发市场</span
          >
          <span>让用户可以通过手机随时随地购物，不再受时间和空间的限制</span>
          <span @click="getProPage(1, 'applet')">进一步了解 →</span>
        </p>
      </dd>
      <dd>
        <p>
          <strong>720云</strong>
          <span>高效创作VR全景/XR/3D/数字人等元宇宙内容</span>
          <span>助力品牌/营销/服务数字化升级</span>
          <span @click="getProPage(6, 'yun')">进一步了解 →</span>
        </p>
      </dd>
      <dd>
        <p>
          <strong>在线学习平台</strong>
          <span>多样化的互动教学，功能丰富，玩法多样</span>
          <span>支持举手提问、视频语音多人连麦、在线答题</span>
          <span>多种学科工具，让教学更高效</span>
          <span @click="getProPage(2, 'platform')">进一步了解 →</span>
        </p>
      </dd>
    </dl>
    <div class="page-white-bg">
      <div class="page-main-box">
        <div class="page-title-box">
          <strong>您需要了解的</strong>
          <span>What you need to know</span>
          <label>让价值共享，记录我们发展脚步，也让您获取更多的知识...</label>
        </div>

        <div class="link-other-box">
          <span @click="getProPage(0, 'payment')">校园支付</span>
          <span @click="getProPage(3, 'storage')">云储存</span>
          <span @click="getProPage(2, 'platform')">在线学习平台</span>
        </div>

        <div class="link-other-box" @click="getMore">
          <label>MORE +</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      currentIndex: 0, //当前所在图片下标
      timer: null, //定时轮询
      imgArr: [
        {
          id: 0,
          url: "./img/bananer/b_img_0.jpg",
          url_m: "./img/bananer/m/b_img_0.jpg",
        },
        {
          id: 1,
          url: "./img/bananer/b_img_5.jpg",
          url_m: "./img/bananer/m/b_img_5.jpg",
        },
      ],
    };
  },
  mounted() {
    this.$emit("viewMenuIndex", 0);
    this.startInterval();
  },
  methods: {
    //开启定时器
    startInterval() {
      // 事件里定时器应该先清除在设置，防止多次点击直接生成多个定时器
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex > this.imgArr.length - 1) {
          this.currentIndex = 0;
        }
      }, 3000);
    },
    // 点击左右箭头
    clickIcon(val) {
      if (val === "down") {
        this.currentIndex++;
        if (this.currentIndex === this.imgArr.length) {
          this.currentIndex = 0;
        }
      } else {
        /* 第一种写法
					this.currentIndex--;
					if(this.currentIndex < 0){
						this.currentIndex = this.imgArr.length-1;
					} */
        // 第二种写法
        if (this.currentIndex === 0) {
          this.currentIndex = this.imgArr.length;
        }
        this.currentIndex--;
      }
    },
    // 点击控制圆点
    changeImg(index) {
      this.currentIndex = index;
    },
    //鼠标移入移出控制
    changeInterval(val) {
      if (val) {
        clearInterval(this.timer);
      } else {
        this.startInterval();
      }
    },
    getMore() {
      this.$emit("viewMenuIndex", 2);
      this.$store.commit("getProLink", "proTop");
      this.$router
        .push({
          path: "/product",
        })
        .catch((err) => err);
    },
    getProPage(mid, val) {
      this.$emit("viewMenuIndex", 2);
      this.$emit("menuProIndex", mid);
      this.$store.commit("getProLink", val);
      this.$router
        .push({
          path: "/product",
        })
        .catch((err) => err);
    },
  },
};
</script>
<style lang="scss">
.link-other-box {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  & span,
  label {
    width: 11.63rem;
    min-height: 3.3rem;
    padding: 1rem 0;
    font-size: 1.46rem;
    text-align: center;
    margin: 0 2.5rem;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    transition: all 0.5s;
  }

  & span {
    color: #ffffff;
    background-image: linear-gradient(to right, #5f3590, #18469c);
    box-shadow: 0 4px 15px 0 rgba(#18469c, 0.75);
  }
  & span:before {
    content: "";
    display: block;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.4) 50%
    );
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  & span:hover {
    color: #ffffff;
  }
  & span:hover:before {
    background-position: left bottom;
  }

  & label {
    border: #5d3c93 solid 0.06rem;
    color: #5d3c93;
    border-radius: 3.31rem;
  }
  & label:hover {
    border: #cdbaec solid 0.06rem;
    color: #cdbaec;
    border-radius: 3.31rem;
  }
}
.image-tab-box {
  overflow: hidden;
  & dd {
    position: relative;
    overflow: hidden;
    min-height: 27.56rem;
    float: left;

    & p {
      position: absolute;
      bottom: 4rem;
      left: 4rem;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      line-height: 2.2rem;

      span:last-child {
        height: 0rem;
        opacity: 0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
      }
    }

    & strong,
    span {
      color: #ffffff;
      position: relative;
      z-index: 9;
    }

    & strong {
      font-size: 1.46rem;
    }
    & span {
      font-size: 1.15rem;
    }
  }
  & dd:hover {
    & p {
      span:last-child {
        opacity: 1;
        height: auto;
        cursor: pointer;
      }
    }
  }
  & dd:before {
    content: "";
    background: linear-gradient(90deg, #5f3590 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    opacity: 0.5;
    filter: blur(0px);
  }
  & dd:hover:before {
    z-index: 8;
    background: linear-gradient(90deg, #535353 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
    filter: blur(2px);
  }
  & dd:first-child {
    background: url("../assets/img/bananer/tab_img_0.jpg") no-repeat center
      center;
    width: 40%;
    background-size: auto 105%;
  }
  & dd:nth-child(2) {
    background: url("../assets/img/bananer/tab_img_1.jpg") no-repeat center
      center;
    width: 60%;
    background-size: auto 105%;
  }
  & dd:nth-child(3) {
    background: url("../assets/img/bananer/tab_img_2.jpg") no-repeat center
      center;
    width: 60%;
    background-size: auto 105%;
  }
  & dd:last-child {
    background: url("../assets/img/bananer/tab_img_3.jpg") no-repeat center
      center;
    width: 40%;
    background-size: auto 105%;
  }
}
.home-tab-box {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  align-items: stretch;
  padding-left: 0.06rem;
  margin: 0 1rem;

  & dt:hover:before {
    background: #eeebf4;
    width: 100%;
  }

  & dt:hover {
    .tab-box-top {
      & strong {
        font-size: 1.15rem;
        color: #5f3590;
      }

      & i {
        font-size: 5rem;
        color: #5f3590;
      }
    }
  }

  & dt {
    width: 20%;
    overflow: hidden;
    border: #e8e8e7 solid 0.06rem;
    margin-left: -0.06rem;
    background: transparent;
    text-transform: uppercase;
    position: relative;

    .tab-box-top {
      border-bottom: #e8e8e7 dashed 0.06rem;
      display: -webkit-flex;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding-bottom: 2.8rem;
      mix-blend-mode: darken;
      color: #666666;

      & strong {
        font-size: 1.15rem;
        padding: 2.5rem 0 2.8rem 0;
        mix-blend-mode: darken;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
      }

      & i {
        font-size: 5rem;
        mix-blend-mode: darken;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
      }
    }
    .tab-box-bottom {
      font-size: 1rem;
      text-align: center;
      overflow: hidden;
      padding: 1rem;
      mix-blend-mode: darken;
      color: #999999;
    }
  }
  & dt:before {
    transition: all 0.8s cubic-bezier(0.7, -0.5, 0.2, 2);
    content: "";
    background: #eeebf4;
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  & dt:last-child {
    display: none;
    opacity: 0;
  }
}

.bananer-box {
  .bananer-box-img {
    & dd img,
    dt img {
      width: 100%;
    }
    & dt {
      display: none;
      opacity: 0;
    }
  }

  .bananer-box-btn {
    height: 6.94rem;
    opacity: 0.25;
    color: #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -3.47rem;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6rem;
  }
  .bananer-box-btn.box-btn-left {
    left: 0;
    i {
      transform: rotate(-90deg);
    }
  }
  .bananer-box-btn.box-btn-right {
    right: 0;
    i {
      transform: rotate(90deg);
    }
  }
}
/* 控制圆点 */
.banner-circle {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  height: 20px;
}
.banner-circle ul {
  height: 100%;
  text-align: center;
}
.banner-circle ul li {
  display: inline-block;
  width: 2rem;
  height: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(125, 125, 125, 0.3);
  cursor: pointer;
}
.active {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

@media screen and (max-width: 1024px) {
  .bananer-box {
    background: #eeebf4;
    height: 75vw;

    & .bananer-box-img {
      & dd {
        display: none;
        opacity: 0;
      }
      & dt {
        display: block;
        opacity: 1;
        img {
          height: 75vw;
        }
      }
    }
  }
  .home-tab-box dt .tab-box-bottom {
    text-align: justify;
  }
  .image-tab-box dd p {
    bottom: 3rem;
    left: 3rem;
  }
  .image-tab-box {
    & dd {
      min-height: 17.5rem;
      & strong {
        font-size: 1.2rem;
      }
      & span {
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: 970px) {
  .home-tab-box {
    flex-wrap: wrap;
    padding-top: 0.06rem;
    & dt {
      width: 33.333333%;
      margin-top: -0.06rem;
    }
    & dt:last-child {
      display: block;
      opacity: 1;
    }
  }
  .image-tab-box {
    display: -webkit-flex;
    display: flex;
    flex-flow: column;

    & dd {
      min-height: 34rem;
    }

    & dd:first-child {
      background: url("../assets/img/bananer/tab_img_0.jpg") no-repeat center
        center;
      background-size: auto 100%;
      width: 100%;
    }
    & dd:nth-child(2) {
      background: url("../assets/img/bananer/tab_img_1.jpg") no-repeat center
        center;
      background-size: auto 100%;
      width: 100%;
    }
    & dd:nth-child(3) {
      background: url("../assets/img/bananer/tab_img_2.jpg") no-repeat center
        center;
      background-size: auto 100%;
      width: 100%;
    }
    & dd:last-child {
      background: url("../assets/img/bananer/tab_img_3.jpg") no-repeat center
        center;
      background-size: auto 100%;
      width: 100%;
    }
  }
}
@media screen and (max-width: 750px) {
  .home-tab-box dt {
    width: 50%;
  }

  .link-other-box span,
  .link-other-box label {
    margin: 0 1rem;
    font-size: 1.1rem;
  }
  .image-tab-box dd {
    min-height: 30rem;
  }
}
@media screen and (max-width: 660px) {
  .bananer-box .bananer-box-btn {
    font-size: 4rem;
    height: 6rem;
    margin-top: -3rem;
  }
  .banner-circle ul li {
    width: 1rem;
    height: 0.25rem;
    margin: 0 0.25rem;
    border-radius: 0.25rem;
  }
  .image-tab-box dd {
    min-height: 27rem;
  }
}
@media screen and (max-width: 530px) {
  .link-other-box {
    padding: 2rem 0.5rem;
  }
  .link-other-box span,
  .link-other-box label {
    margin: 0 0.5rem;
  }
  .image-tab-box dd p {
    right: 3rem;
  }
  .image-tab-box dd {
    min-height: 22rem;
  }
}
@media screen and (max-width: 430px) {
  .image-tab-box dd {
    min-height: 18rem;
  }
  .image-tab-box dd p {
    bottom: 1.8rem;
    left: 1.8rem;
    right: 1.8rem;
  }
}
</style>
