import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pageVal: '/',
		flag: null,
		proLink:'proTop'
	},
	getters: {},
	mutations: {
		getPage(state, val) {
			state.pageVal = val
		},
		getFlag(state, val) {
			state.flag = val
		},
		getProLink(state, val) {
			state.proLink = val
		}
	},
	actions: {},
	modules: {}
})